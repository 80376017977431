import { graphql } from "gatsby";
import React from "react";
import SEO from "@components/seo";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import BlogPost from "@components/BlogPost";
import BlogPostFilters from "@components/BlogPostFilters";
import { slice } from "lodash";
import Pagination from "@components/Pagination";
import SliderHero from "@components/SliderHero";

const POSTS_PER_PAGE = 9;

const BlogPage = ({ data }) => {
  //<Image fluid={datoCmsAbout.gallery[0].fluid} />
  const posts = data.allDatoCmsBlogPost.edges;
  const seoTitle = `Hawlik Gerginski Architekten | Impulse`;
  const seoDescription = `Vertrauen und Wertschätzung – Motivation`;

  const postNodes = posts.map(post => post.node);
  const blogPosts = postNodes; //orderBy(postNodes, ["postDate"], ["desc"]);
  const blogPostsSliced = slice(blogPosts, 0, 9);
  const featuredImages = data.datoCmsImpulseseite?.featuredImages;
  const seoImage = featuredImages ? featuredImages[0].fluid.src : null;

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <SliderHero
        items={featuredImages}
        pageTitle="Impulse"
        pageSlogan="Vertrauen und Wertschätzung – Motivation"
      />
      <Container>
        <BlogPostFilters />
        <Div
          flex
          css={css`
            flex-wrap: wrap;

            @media (min-width: 768px) {
              margin: 0px -16px;
            }
          `}
        >
          {blogPostsSliced.map((post, index) => {
            return (
              <BlogPost
                key={`post--${index}`}
                id={`post--${index}`}
                {...post}
              />
            );
          })}
        </Div>
      </Container>
      <Pagination
        currentPage={1}
        pageCount={Math.ceil(blogPosts.length / POSTS_PER_PAGE)}
        baseUrl={`/blog`}
      />
    </React.Fragment>
  );
};

export default BlogPage;

export const query = graphql`
  query PostsQuery {
    datoCmsImpulseseite {
      featuredImages {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    allDatoCmsBlogPost(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          postTitle
          postDate
          slug
          excerpt
          fragen
          news
          downloads
          projekte
          twofiftyDinge
          featuredImage {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
